<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="病变编辑"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>闭塞段位置</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/CE_blood_vessel_block_section_position"
          :value.sync="cerebralEmbolismTableInfo.blockSectionPosition"
        />
      </div>
      <div class="item-row">
        <span>闭塞病变数量</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/CE_blood_vessel_occlusive_lesion_count"
          :value.sync="cerebralEmbolismTableInfo.occlusiveLesionCount"
        />
      </div>
      <!-- <div class="item-row">
        <span>术前NIHSS评分</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="分"
          v-model="cerebralEmbolismTableInfo.nihssScore"
        />
      </div> -->
      <div class="item-row">
        <span>既往有无介入治疗史</span>
        <cqt-radio
          :value.sync="cerebralEmbolismTableInfo.hasInterventionalTherapy"
          :isReadonly="isReadonly"
        >
          <el-radio label="有">有</el-radio>
          <el-radio label="无">无</el-radio>
        </cqt-radio>
      </div>
      <div class="item-row">
        <span>既往球囊扩张史/支架植入史/取栓史</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/CE_blood_vessel_has_ptra_pci_embolectomy"
          :value.sync="cerebralEmbolismTableInfo.hasPtraPciEmbolectomy"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>既往球囊/支架类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/CE_blood_vessel_balloon_or_stentType"
          :value.sync="cerebralEmbolismTableInfo.balloonOrStentType"
        />
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <el-col v-show="!isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-radio label="择期介入取栓" v-model="handlingOpinions">择期介入取栓</el-radio>
        <el-radio label="择期溶栓" v-model="handlingOpinions">择期溶栓</el-radio>
        <el-radio label="药物治疗" v-model="handlingOpinions">药物治疗</el-radio>
        <el-radio label="择期球囊扩张" v-model="handlingOpinions">择期球囊扩张</el-radio>
        <el-radio label="本次治疗" v-model="handlingOpinions">本次治疗</el-radio>
      </el-col>
      <el-col v-show="isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="handlingOpinions">
          {{ handlingOpinions }}
        </el-tag>
      </el-col>
      <el-col v-show="!isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <current-checkbox-select
          v-if="handlingOpinions === '本次治疗'"
          v-model="currentTreatment"
          :currentTreatment="this.currentTreatment"
        ></current-checkbox-select>
      </el-col>
      <el-col v-show="isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="currentTreatment">
          {{ currentTreatment }}
        </el-tag>
      </el-col>
    </div>
    <div class="button-panel">
      <el-button
        <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'
import CurrentCheckboxSelect from './CurrentCheckboxSelect.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'
export default {
  name: 'CerebralEmbolismInfoDialog',
  components: {
    CmDialogBorder,
    CurrentCheckboxSelect,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    nerveCeId: {
      type: String,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      handlingOpinions: ' ',
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      currentTreatment: null,
      cerebralEmbolismTableInfo: {
        nerveCeId: null, //脑栓塞主表id
        blockSectionPosition: null, //闭塞段位置
        occlusiveLesionCount: null, //闭塞病变数量
        nihssScore: null, //术前NIHSS评分
        hasInterventionalTherapy: null, //既往有无介入治疗史
        hasPtraPciEmbolectomy: null, //既往球囊扩张史/支架植入史/取栓史
        balloonOrStentType: null, //既往球囊/支架类型
        handlingOpinion: null //处理意见
      },
      handlingOpinionsInfo: []
    }
  },
  watch: {
    handlingOpinions: {
      handler(val) {
        this.cerebralEmbolismTableInfo.handlingOpinion = val
      }
    },

    currentTreatment: {
      handler(val) {
        this.cerebralEmbolismTableInfo.handlingOpinions = '本次治疗'

        if (val && val.length > 0) {
          this.cerebralEmbolismTableInfo.handlingOpinion = ['本次治疗', ...val.split('|')].join('+')
        }
      }
    }
  },
  created() {},
  methods: {
    Show(bloodVessel) {
      this.cerebralEmbolismTableInfo = {}
      this.currentTreatment = ''
      if (bloodVessel) this.cerebralEmbolismTableInfo = deepClone(bloodVessel)

      let sugs = this.cerebralEmbolismTableInfo.handlingOpinion?.split('+') || []
      this.handlingOpinions = sugs[0] || ''
      if (sugs.length > 0) {
        this.currentTreatment = sugs?.slice(1).join('|')
      }

      this.isShow = true
    },
    Save() {
      if (this.isReadonly) return
      if (
        this.cerebralEmbolismTableInfo.blockSectionPosition === null ||
        this.cerebralEmbolismTableInfo.blockSectionPosition === ''
      ) {
        return this.$message.warning('闭塞段位置不能为空')
      }
      // if (this.nerveCeId != '-1' && this.nerveCeId != '') {
      var idx = this.cerebralEmbolismTableInfo.handlingOpinion?.indexOf('++') || -1
      if (idx != -1) {
        var a = this.cerebralEmbolismTableInfo.handlingOpinion.substr(0, idx + 1)
        var b = this.cerebralEmbolismTableInfo.handlingOpinion.substr(idx + 2)
        this.cerebralEmbolismTableInfo.handlingOpinion = a + b
      }

      this.$emit('save', this.cerebralEmbolismTableInfo)
      this.Close()
      // }
    },

    Close() {
      this.isShow = false
      // this.handlingOpinions = ''
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
